
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Détail d'une commande | Livraisons",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      drawerHisto: ref(false),
      datesHisto: [],
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
    });
    const tableHeader = ref([
      {
        name: "",
        key: "liv_seq",
        sortable: false,
      },
      {
        name: "Référence / Désignation",
        key: "loc_designation",
        sortable: false,
      },
      {
        name: "Qté",
        key: "liv_qte",
        sortable: true,
      },
      {
        name: "Livraison",
        key: "liv_date_liv",
        sortable: true,
      },
      {
        name: "Statut",
        key: "liv_m_status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "sn_tot",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Statut",
        key: "liv_m_status",
        label: "liv_m_status",
      },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };
    const formatMoney = (n) => {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (status) => {
      if (status == "Offre validée") return "btn-icon-success";
      if (status == "Offre déclinée") return "btn-icon-danger";
      return "btn-icon-info";
    };

    const styleImgStatus = (status) => {
      if (status == "Offre validée")
        return "media/icons/duotune/general/gen048.svg";
      if (status == "Offre déclinée")
        return "media/icons/duotune/general/gen050.svg";
      return "media/icons/duotune/general/gen049.svg";
    };

    const openSN = async (data) => {
      const resultAdr = await getAxios("/getSN/" + data.liv_seq);

      state.lSN = resultAdr.results;
      state.mObj = data;
      state.drawer = true;
    };


    const openHisto = async (data) => {
      const resultAdr = await getAxios("/getHistDates/" + data.liv_seq);
      state.datesHisto = resultAdr.results;
      state.mObj = data;
      state.drawerHisto = true;
    };

    const formatHoverDateD = (data) => {
      if (!data.liv_date_old_liv) {
        return `La date de livraison n'a connue aucune variation depuis le ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.liv_date_maj
      )}, la date de livraison est passée du ${formatDate(
        data.liv_date_old_liv
      )} au ${formatDate(data.liv_date_liv)}`;
    };

    const isRefMajeur = (d) => {
      const listTmp = state.myList.filter(
        // .lof_knum_ligne_groupe === d.lof_seq
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => item.lof_knum_ligne_groupe === d.liv_seq  
      );
      return listTmp.length > 0;
    }

    const isRefMajeurLN = (d) => {
      console.log("Ref MAJ", d);
      if (d.lof_line_number) {
        return d.lof_line_number.indexOf('.0') > 0;
      } else {
        return true;
      }
      
    }

    const openSubItem = (d) => {
      state.myList.forEach(el => {         
          if (el.liv_seq === d.liv_seq) {
            el.opensub = d.opensub == 0 ? 1 : 0;
          }
          if (el.lof_knum_ligne_groupe === d.liv_seq) {
            el.displayrow = d.opensub == 0 ? 1 : 2;
          }

        });      
    }


    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Détail d'une commande", [
        "Home",
        "Board Stillnetwork",
      ]);

      const codeToc = router.currentRoute.value.params.code as string;
      let myListLiv = await getAxios("/getLivraisons/" + codeToc);

      state.myList = myListLiv.results;
      let curMajeur = 0;


      state.myList.forEach(el => {
        if( el.lof_line_number && (el.lof_line_number.indexOf('.') ==  el.lof_line_number.lastIndexOf('.')) && (el.lof_line_number.indexOf('.0') > 0 ) ) {
          curMajeur = el.liv_seq;
          el.opensub = 0
          el.displayrow = 1
        } else if (el.lof_line_number && el.lof_line_number.length >0 && curMajeur>0 ) {
          el.lof_knum_ligne_groupe = curMajeur;
          el.displayrow = 2
        }
        // console.log(el.liv_seq, el.lof_knum_ligne_groupe)
      });

      
      /*
      state.myList.forEach(el => {  
        if (el.lof_knum_ligne_groupe > 0) {
          const listTmp = state.myList.filter(
            (item: any) => item.lof_seq === el.lof_knum_ligne_groupe  
          );
          if (listTmp.length==0) el.displayrow = 0;
        }


      });  

      */

      state.initialMyList = state.myList;
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatHoverDateD,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      openSN,
      openHisto,
      tableFilters,
      isRefMajeur,
      isRefMajeurLN,
      openSubItem
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
